<template>
  <div id="app">

    <v-snackbar v-model="snackbartest.visible" auto-height :color="snackbartest.color"
      :multi-line="snackbartest.mode === 'multi-line'" :timeout="snackbartest.timeout"
      :top="snackbartest.position === 'top'">
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>{{ snackbartest.icon }}</v-icon>
        <v-layout column>
          <div>
            <strong>{{ snackbartest.title }}</strong>
          </div>
          <div>{{ snackbartest.text }}</div>
        </v-layout>
      </v-layout>
      <v-btn v-if="snackbartest.timeout === 0" icon @click="snackbartest.visible = false">
        <v-icon>clear</v-icon>
      </v-btn>
    </v-snackbar>

    <v-data-table :headers="headers" :items="list" item-key="id" sort-by="code" class="elevation-1 ma-3"
      @click:row="rowClick">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>LISTE DES MULTIMEDIA</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" max-width="650px">
            <v-snackbar v-model="snackbar" :multi-line="multiLine" color="primary">
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                  Fermer
                </v-btn>
              </template>
            </v-snackbar>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="nouveau()">
                Nouveau
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid" lazy-validation>

                    <v-text-field v-model="titre" label="TITRE *" dense outlined>
                    </v-text-field>

                    <v-text-field v-model="autres" label="AUTRES *" dense outlined>
                    </v-text-field>

                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="blue darken-1" text @click="dialog = false">
                  Fermer
                </v-btn>

                <v-btn color="blue darken-1" text @click="save">
                  Enregistrer
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Voulez-vous vraiment supprimer?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete = false">Annuller</v-btn>
                <v-btn color="blue darken-1" text @click="supdata()">Confirmer</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>


      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>


      <template v-slot:no-data>
        <v-btn color="primary" @click="Actulisation">
          Actualiser la liste
        </v-btn>
      </template>
    </v-data-table>

    <v-spacer></v-spacer>
    <v-spacer></v-spacer>

    <h3 class="text-center" v-html="message"></h3>

    <v-data-table :headers="headersdetail" :items="listdetail" class="elevation-1 ma-3">

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>PHOTOTECQUE</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <v-dialog v-model="dialogDetail" max-width="650px">
            <v-snackbar v-model="snackbarDetail" :multi-line="multiLine" color="primary">
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbarDetail = false">
                  Fermeture
                </v-btn>
              </template>
            </v-snackbar>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="nouveauDetail()">
                Nouveau
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid" lazy-validation>


                    <v-file-input v-model="multimedia" label="Multimedia *" dense outlined>
                    </v-file-input>

                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="blue darken-1" text @click="dialogDetail = false">
                  Fermer
                </v-btn>

                <v-btn color="blue darken-1" text @click="saveDetail">
                  Enregistrer
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDeleteDetail" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Voullez vous vraiment supprimer?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDeleteDetail = false">Annuller</v-btn>
                <v-btn color="blue darken-1" text @click="supdataDetail()">Confirmer</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.multimedia`]="{ item }">
        <div class="p-2">
          <v-img :src="base_url + item.multimedia" :alt="item.id" height="50px" width="50"></v-img>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItemDetail(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItemDetail(item)"> mdi-delete </v-icon>
      </template>

    </v-data-table>


    <v-data-table :headers="headersdetailvideo" :items="listdetailvideo" class="elevation-1 ma-3">

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>VIDEOTEQUE</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <v-dialog v-model="dialogDetailvideo" max-width="650px">
            <v-snackbar v-model="snackbarDetailvideo" :multi-line="multiLine" color="primary">
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbarDetailvideo = false">
                  Fermeture
                </v-btn>
              </template>
            </v-snackbar>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="nouveauDetailvideo()">
                Nouveau
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid" lazy-validation>


                    <v-text-field v-model="multimediavideo" label="Lien Youtube  *" dense outlined>
                    </v-text-field>

                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="blue darken-1" text @click="dialogDetailvideo = false">
                  Fermer
                </v-btn>

                <v-btn color="blue darken-1" text @click="saveDetailvideo">
                  Enregistrer
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDeleteDetailvideo" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Voullez vous vraiment supprimer?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDeleteDetailvideo = false">Annuller</v-btn>
                <v-btn color="blue darken-1" text @click="supdataDetailvideo()">Confirmer</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

  

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItemDetailvideo(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItemDetailvideo(item)"> mdi-delete </v-icon>
      </template>

    </v-data-table>


  </div>
</template>

<script>
import api from "./../../../serviceApi/apiService";

export default {
  data: () => ({

    base_url: "http://masterapi.piebarometre.digital/masterapi/storage/app/public/media/",

    multiLine: true,
    snackbartest: false,
    textmessage: `message`,

    snackbar: false,
    snackbars: false,
    text: `message`,
    textsnackbar: "messages",
    dialog: false,
    dialogDelete: false,


    headers: [
      { text: "ID", value: "id", divider: true, width: "30px" },
      { text: "TITRE", value: "title", divider: true, width: "550px" },
      {
        text: "AUTRES",
        value: "other",
        divider: true,
        width: "100px",
      },
      { text: "ACTIONS", value: "actions", divider: true, width: "100px" },
    ],

    snackbarDetail: false,
    dialogDetail: false,
    dialogDeleteDetail: false,
    headersdetail: [
      { text: "ID", value: "id", divider: true, width: "30px" },
      { text: "MULTIMEDIA", value: "multimedia", divider: true, width: "550px" },
      { text: "ACTIONS", value: "actions", divider: true, width: "100px" },
    ],

    snackbarDetailvideo: false,
    dialogDetailvideo: false,
    dialogDeleteDetailvideo: false,
    headersdetailvideo: [
      { text: "ID", value: "id", divider: true, width: "30px" },
      { text: "LIEN VIDEO YOUTUBE", value: "multimedia", divider: true, width: "550px" },
      { text: "ACTIONS", value: "actions", divider: true, width: "100px" },
    ],

    list: [],
    id: "-1",
    titre: "",
    autres: "",
    textetat: false,

    listdetail: [],
    idDetail: "0",
    multimedia: "",
    detailetat: false,

    listdetailvideo: [],
    idDetailvideo: "0",
    multimediavideo: "",
    detailetatvideo: false,

    message: "",

    formTitle: "Formulaire d'enregistrement",
  }),
  methods: {
    //=====Afficher tout=======
    readAll: async function () {
      const data = await api.readAll("galery/all");
      this.list = data.list;
    },

    readAllDetail: async function (id) {
      const data = await api.readAll("galery/get-line/" + id);
      this.listdetail = data.list;
    },

    readAllDetailvideo: async function (id) {
      const data = await api.readAll("galery/get-video/" + id);
      this.listdetailvideo = data.list;
    },

    createUpdatedata: async function () {
      let fd = new FormData();
      fd.append("title", this.titre);
      fd.append("other", this.autres);
      fd.append("id", this.id);

      if (this.textetat) { // update galery
        //console.log("update event");
        const res = await api.createUpdatedata(
          "galery/update",
          fd
        );
        if (res.status == 200) {
          this.textmessage = res.message;
          this.snackbartest = {
            color: "green",
            icon: "mdi-check-all",
            mode: "multi-line",
            position: "top",
            timeout: 2500,
            title: "Information",
            text: this.textmessage,
            visible: true,
          };
          this.dialog = false;
        } else {
          this.textmessage = res.message;
          this.snackbartest = {
            color: "green",
            icon: "mdi-check-all",
            mode: "multi-line",
            position: "top",
            timeout: 2500,
            title: "Information",
            text: this.textmessage,
            visible: true,
          };
        }


      } else { // add new galery

        const res = await api.createUpdatedata("galery/add", fd);
        if (res.status == 200) {
          this.textmessage = res.message;
          this.snackbartest = {
            color: "green",
            icon: "mdi-check-all",
            mode: "multi-line",
            position: "top",
            timeout: 2500,
            title: "Information",
            text: this.textmessage,
            visible: true,
          };
        } else {
          this.textmessage = res.message;
          this.snackbartest = {
            color: "green",
            icon: "mdi-check-all",
            mode: "multi-line",
            position: "top",
            timeout: 2500,
            title: "Information",
            text: this.textmessage,
            visible: true,
          };
        }

      }

      this.clear();
      this.readAll();
    },

    createUpdatedataDetail: async function () {
      let fd = new FormData();
      fd.append("multimedia", this.multimedia);
      fd.append("id_gallery", this.id);
      fd.append("id", this.idDetail);

      if (this.detailetat) { // update galery-detail
        const res = await api.createUpdatedata(
          "galery/update-line",
          fd
        );
        if (res.status == 200) {
          this.textmessage = res.message;
          this.snackbartest = {
            color: "green",
            icon: "mdi-check-all",
            mode: "multi-line",
            position: "top",
            timeout: 2500,
            title: "Information",
            text: this.textmessage,
            visible: true,
          };
          this.dialogDetail = false;
        } else {
          this.textmessage = res.message;
          this.snackbartest = {
            color: "green",
            icon: "mdi-check-all",
            mode: "multi-line",
            position: "top",
            timeout: 2500,
            title: "Information",
            text: this.textmessage,
            visible: true,
          };
        }
      } else { // add new galery-detail
        const res = await api.createUpdatedata("galery/add-line", fd);
        if (res.status == 200) {
          this.textmessage = res.message;
          this.snackbartest = {
            color: "green",
            icon: "mdi-check-all",
            mode: "multi-line",
            position: "top",
            timeout: 2500,
            title: "Information",
            text: this.textmessage,
            visible: true,
          };
        } else {
          this.textmessage = res.message;
          this.snackbartest = {
            color: "green",
            icon: "mdi-check-all",
            mode: "multi-line",
            position: "top",
            timeout: 2500,
            title: "Information",
            text: this.textmessage,
            visible: true,
          };
        }
      }

      this.detailetat = false;
      this.readAllDetail(this.id);
    },

    createUpdatedataDetailvideo: async function () {
      let fd = new FormData();
      fd.append("multimedia", this.multimediavideo);
      fd.append("id_gallery", this.id);
      fd.append("id", this.idDetailvideo);

      if (this.detailetatvideo) { // update galery-detailvideo
        const res = await api.createUpdatedata(
          "galery/update-video",
          fd
        );
        if (res.status == 200) {
          this.textmessage = res.message;
          this.snackbartest = {
            color: "green",
            icon: "mdi-check-all",
            mode: "multi-line",
            position: "top",
            timeout: 2500,
            title: "Information",
            text: this.textmessage,
            visible: true,
          };
          this.dialogDetailvideo = false;
        } else {
          this.textmessage = res.message;
          this.snackbartest = {
            color: "green",
            icon: "mdi-check-all",
            mode: "multi-line",
            position: "top",
            timeout: 2500,
            title: "Information",
            text: this.textmessage,
            visible: true,
          };
        }
      } else { // add new galery-detailvideo
        const res = await api.createUpdatedata("galery/add-video", fd);
        if (res.status == 200) {
          this.textmessage = res.message;
          this.snackbartest = {
            color: "green",
            icon: "mdi-check-all",
            mode: "multi-line",
            position: "top",
            timeout: 2500,
            title: "Information",
            text: this.textmessage,
            visible: true,
          };
        } else {
          this.textmessage = res.message;
          this.snackbartest = {
            color: "green",
            icon: "mdi-check-all",
            mode: "multi-line",
            position: "top",
            timeout: 2500,
            title: "Information",
            text: this.textmessage,
            visible: true,
          };
        }
      }

      this.detailetatvideo = false;
      this.readAllDetailvideo(this.id);
    },

    delete: async function () {
      let fd = new FormData();
      fd.append("id", this.id);
      //console.log(this.id)
      const res = await api.createUpdatedata("galery/delete", fd);
      this.readAll();
      this.dialogDelete = false;
    },

    deleteDetail: async function () {
      let fd = new FormData();
      fd.append("id", this.idDetail);
      const res = await api.createUpdatedata("galery/delete-line", fd);
      this.readAllDetail(this.id);
      this.dialogDeleteDetail = false;
    },

    deleteDetailvideo: async function () {
      let fd = new FormData();
      fd.append("id", this.idDetailvideo);
      const res = await api.createUpdatedata("galery/delete-video", fd);
      this.readAllDetailvideo(this.id);
      this.dialogDeleteDetailvideo = false;
    },

    supdata() {
      this.delete();
    },

    supdataDetail() {
      this.deleteDetail();
    },

    supdataDetailvideo() {
      this.deleteDetailvideo();
    },

    save() {

      if (!this.textetat) { // add new event
        //console.log("save event "+this.lien_image);
        if (this.titre == "") {
          this.textmessage = "Veuillez remplir les champs obligatoires";
          this.snackbartest = {
            color: "red",
            icon: "mdi-alert",
            mode: "multi-line",
            position: "top",
            timeout: 2500,
            title: "Erreur",
            text: this.textmessage,
            visible: true,
          };
          return;
        }
      } else { // update event

        if (this.titre == "") {
          this.textmessage = "Veuillez remplir les champs obligatoires update";
          this.snackbartest = {
            color: "red",
            icon: "mdi-alert",
            mode: "multi-line",
            position: "top",
            timeout: 2500,
            title: "Erreur",
            text: this.textmessage,
            visible: true,
          };
          return;
        }
      }
      this.createUpdatedata();
    },

    saveDetail() {
      console.log('id : ' + this.id);
      if (this.id == "" || this.id == "-1") {
        this.textmessage = "Veuillez selectionner une galerie";
        this.snackbartest = {
          color: "red",
          icon: "mdi-alert",
          mode: "multi-line",
          position: "top",
          timeout: 2500,
          title: "Erreur",
          text: this.textmessage,
          visible: true,
        };
        return;
      } else {
        if (this.multimedia == "") {
          this.textmessage = "Veuillez selectionner une image";
          this.snackbartest = {
            color: "red",
            icon: "mdi-alert",
            mode: "multi-line",
            position: "top",
            timeout: 2500,
            title: "Erreur",
            text: this.textmessage,
            visible: true,
          };
          return;
        } else {
          this.createUpdatedataDetail();
        }
      }
    },

    saveDetailvideo() {
      console.log('id : ' + this.id);
      if (this.id == "" || this.id == "-1") {
        this.textmessage = "Veuillez selectionner un multimedia";
        this.snackbartest = {
          color: "red",
          icon: "mdi-alert",
          mode: "multi-line",
          position: "top",
          timeout: 2500,
          title: "Erreur",
          text: this.textmessage,
          visible: true,
        };
        return;
      } else {
        if (this.multimediavideo == "") {
          this.textmessage = "Veuillez selectionner une image";
          this.snackbartest = {
            color: "red",
            icon: "mdi-alert",
            mode: "multi-line",
            position: "top",
            timeout: 2500,
            title: "Erreur",
            text: this.textmessage,
            visible: true,
          };
          return;
        } else {
          this.createUpdatedataDetailvideo();
        }
      }
    },

    nouveau() {
      this.textetat = false;
      this.clear();
      this.dialog = true;
    },

    nouveauDetail() {
      this.detailetat = false;
      this.dialogDetail = true;
    },

    nouveauDetailvideo() {
      this.detailetatvideo = false;
      this.dialogDetailvideo = true;
    },

    editItem(item) {
      this.id = item.id;
      this.titre = item.title;
      this.autres = item.other;
      this.textetat = true;
      this.dialog = true;
    },

    editItemDetail(item) {
      this.idDetail = item.id;
      this.detailetat = true;
      this.dialogDetail = true;
    },

    editItemDetailvideo(item) {
      console.log(item);
      this.idDetailvideo = item.id;
      this.multimediavideo = item.multimedia;
      this.detailetatvideo = true;
      this.dialogDetailvideo = true;
    },

    deleteItem(item) {
      this.id = item.id;
      this.dialogDelete = true;
    },

    deleteItemDetail(item) {
      this.idDetail = item.id;
      this.dialogDeleteDetail = true;
    },

    deleteItemDetailvideo(item) {
      this.idDetailvideo = item.id;
      this.dialogDeleteDetailvideo = true;
    },

    clear() {
      this.id = "-1";
      this.titre = "";
      this.autres = "";
      this.textetat = false;
    },

    clearDetail() {
      this.idDetail = "0";
      this.detailetat = false;
    },

    clearDetailvideo() {
      this.idDetailvideo = "0";
      this.multimediavideo = "";
      this.detailetatvideo = false;
    },

    rowClick(item) {
      this.id = item.id;
      this.message = item.title;
      this.readAllDetail(item.id);
      this.readAllDetailvideo(item.id);
    },
  },



  mounted() { // this is called after the render function
    this.readAll();
  },
};

</script>

<style lang="scss" scoped>
.app-content-wrapper {
  position: relative;
  display: flex;
  overflow: hidden;
  height: calc(100vh - 200px);

  .app-overlay {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    display: none;
    z-index: 2;
    transition: all 0.3s ease-in;

    @media only screen and (max-width: 959px) {
      &.open {
        display: block;
        transition: all 0.3s ease-in;
      }
    }
  }

  .app-sidebar {
    width: 280px;
    position: relative;
    transition: all 0.3s ease-in;

    @media only screen and (max-width: 959px) {
      position: absolute;
      left: -340px;
      z-index: 5;
      height: calc(100vh - 120px);
      transition: all 0.3s ease-in;

      &.open {
        left: 0;
      }
    }

    .app-sidebar-header {
      padding: 0.75rem 1.25rem;
      margin-bottom: 0;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    .app-sidebar-body {
      // overflow-y: scroll;
      // height: 100%;
      padding-top: 3.375rem;
      width: 280px;
    }
  }

  .app-content {
    width: calc(100% - 280px);

    @media only screen and (max-width: 959px) {
      width: 100%;
    }

    // margin-left: 30px;
    .app-header {
      padding: 0.75rem 1.25rem;
      margin-bottom: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    .app-body {
      flex: 1 1 auto;
      padding-top: 3.375rem;
      // overflow-y: scroll;
      height: calc(100% - 130px);
    }

    .app-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 130px;
    }
  }
}

.app-icon {
  display: none;

  @media only screen and (max-width: 959px) {
    display: block;
  }
}

.eg-filemanager {
  background-color: #fff;
}

.filemanager-content {
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
}

.apps-wrapper {
  &.--filemanager {
    .nested-sidebar-toggle {
      @media (min-width: 959px) {
        display: none;
      }
    }
  }
}
</style>